<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script>
import { version } from "../package.json";
import { colors, SessionStorage } from "quasar";

export default {
  name: "App",
  async preFetch({ route, store, redirect, rootState }) {
    console.info("Build Version: ", version);
    try {
      let token = route?.query?.token;
      if (!token) {
        token = SessionStorage.getItem('token');
      }
      await store.dispatch("auth/config", { token });

      colors.setBrand(
        "primary",
        store.state.auth.config.branding.colors.secondary
        //store.state.auth.config.branding.colors.primary
      );
      colors.setBrand(
        "secondary",
        store.state.auth.config.branding.colors.primary
        //store.state.auth.config.branding.colors.secondary
      );
      colors.setBrand("accent", store.state.auth.config.branding.colors.accent);
      colors.setBrand(
        "negative",
        store.state.auth.config.branding.colors.negative
      );
      colors.setBrand(
        "positive",
        store.state.auth.config.branding.colors.positive
      );
      colors.setBrand("dark", store.state.auth.config.branding.colors.dark);
      colors.setBrand("info", store.state.auth.config.branding.colors.info);
      colors.setBrand(
        "warning",
        store.state.auth.config.branding.colors.warning
      );

      // load fonts configurations
      document.documentElement.style.setProperty(
        "--bodyFontFamily",
        store.state.auth.config.branding.fonts.body.fontStyle.fontFamily
      );
      document.documentElement.style.setProperty(
        "--bodyFontStyle",
        store.state.auth.config.branding.fonts.body.fontStyle.fontStyle
      );
      document.documentElement.style.setProperty(
        "--bodyFontWeight",
        store.state.auth.config.branding.fonts.body.fontStyle.fontWeight
      );
      document.documentElement.style.setProperty(
        "--bodyFontSize",
        store.state.auth.config.branding.fonts.body.fontStyle.fontSize
      );

      const favicon = document.getElementById("favicon");
      const cdnUrl = store.state.auth.config.branding.cdnUrl;
      favicon.type =
        store.state.auth.config.branding.metas.link.favicon_16x16.type;
      favicon.href =
        cdnUrl + store.state.auth.config.branding.metas.link.favicon_16x16.href;
    } catch (e) {
      console.error('Something went wrong. Cannot get "auth/config" data.');
    }
  },
  created() {},
  mounted() {},
  meta() {
    const metas = this.$store.state?.auth?.config?.branding?.metas;
    return metas;
  },
};
</script>
<style>
@import "css/sandals/sandals-icons.css";
</style>
