// import Vue from 'vue'
// import Router from 'vue-router'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
// import * as Integrations from '@sentry/integrations'

export default async ({ Vue, redirect, store, router }) => {
  if (process.env.ENV === 'Local' || process.env.ENV === 'testing') {
    return
  }

  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.ENV, process.env.SENTRY_APP_HOST, /^\//]
      })
    ],
    ignoreErrors: ['Network request failed', 'Failed to fetch', 'NetworkError'],
    environment: process.env.ENV,
    tracesSampleRate: 1.0
  })
}

// Sentry.init({
//   Vue,
//   dsn:
//     'https://20c36bf487de48ba8b61c40a41da864b@o1345559.ingest.sentry.io/6622271',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

// export default boot(({ Vue }) => {
//   Sentry.init({
//     dsn:
//       'https://20c36bf487de48ba8b61c40a41da864b@o1345559.ingest.sentry.io/6622271',
//     release: process.env.CI_COMMIT_SHORT_SHA,
//     integrations: [
//       new Integrations.Vue({ Vue, attachProps: true }),
//       new Integrations.RewriteFrames({
//         iteratee(frame) {
//           // Strip out the query part (which contains `?__WB_REVISION__=**`)
//           frame.abs_path = frame.abs_path.split('?')[0]

//           return frame
//         }
//       })
//     ]
//   })
// })
