import Vue from 'vue'
import Vuex from 'vuex'

const modulesFolder = process.env.DEV_STATE ? 'sections' : 'sections'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

let store = null;

export default async function (/* { ssrContext } */) {
  const ui = (await import(`./${modulesFolder}/ui`)).default;
  const dates = (await import(`./${modulesFolder}/dates`)).default;
  const teams = (await import(`./${modulesFolder}/teams`)).default;
  const agents = (await import(`./${modulesFolder}/agents`)).default;
  const sessions = (await import(`./${modulesFolder}/sessions`)).default;
  const contentStats = (await import(`./${modulesFolder}/contentStats`)).default;
  const destinations = (await import(`./${modulesFolder}/destinations`)).default;
  const auth = (await import(`./${modulesFolder}/auth`)).default;
  const home = (await import(`./${modulesFolder}/home`)).default
  const config = {
    modules: {
      ui,
      teams,
      agents,
      sessions,
      contentStats,
      destinations,
      auth,
      home,
      dates
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING
  };
  if(store == null) {
    store = new Vuex.Store(config)
  }
  return store
}

export { store }
