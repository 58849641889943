import { CenoraInternalCommunication } from '@cenora-llc/cenora-internal-communication-library'

export default async ({ store }) => {
  await CenoraInternalCommunication.init(
    [
    ],
    {
      emitBasicEvents: true,
      subscribeForBasicEvents: false
    }
  )
}
