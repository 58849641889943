const routes = [
  {
    path: "/dev-login",
    name: "login",
    component: () => import("pages/Auth/Login.vue")
  },
  {
    path: "/portal",
    name: "portal",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        redirect: "dashboard"
      },
      {
        path: "dashboard",
        component: () => import("pages/Dashboard/DashboardLayout.vue"),
        children: [
          {
            name: "home",
            path: "",
            component: () => import("pages/Dashboard/Home/Home.vue"),
            meta: {
              title: "Dashboard",
              icon: "fas fa-home",
              tabs: [],
              breadcrumb: [
                {
                  icon: "home",
                  label: "Home",
                  link: { name: "home" }
                }
              ]
            }
          },
          {
            path: "teams",
            component: () => import("pages/Dashboard/Teams/Teams.vue"),
            children: [
              {
                path: "",
                name: "teams-all",
                component: () => import("pages/Dashboard/Teams/All.vue"),
                meta: {
                  title: "Teams",
                  icon: "fas fa-user-friends",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Teams"
                    }
                  ]
                }
              },
              {
                name: "team",
                path: ":id",
                component: () => import("pages/Dashboard/Teams/One.vue"),
                meta: {
                  title: "Teams",
                  icon: "fas fa-user-friends",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Teams",
                      link: { name: "teams-all" }
                    }
                  ]
                }
              }
            ]
          },
          {
            path: "agents",
            name: "agents",
            component: () => import("pages/Dashboard/Agents/Agents.vue"),
            children: [
              {
                name: "agents-all",
                path: "",
                component: () => import("pages/Dashboard/Agents/All.vue"),
                meta: {
                  title: "Agents",
                  icon: "fas fa-users",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Agents"
                    }
                  ]
                }
              },
              {
                name: "agent",
                path: ":id",
                component: () => import("pages/Dashboard/Agents/One.vue"),
                meta: {
                  title: "Agents",
                  icon: "fas fa-users",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Agents",
                      link: { name: "agents-all" }
                    }
                  ]
                }
              }
            ]
          },
          {
            name: "sessions",
            path: "sessions",
            component: () => import("pages/Dashboard/Sessions/Sessions.vue"),
            children: [
              {
                name: "sessions-all",
                path: "",
                component: () => import("pages/Dashboard/Sessions/All.vue"),
                meta: {
                  title: "Sessions",
                  icon: "fas fa-mobile-alt",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Sessions"
                    }
                  ]
                }
              },
              {
                name: "sessions-agent",
                path: ":id",
                component: () => import("pages/Dashboard/Sessions/All.vue"),
                meta: {
                  title: "Sessions",
                  icon: "fas fa-mobile-alt",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Sessions",
                      link: { name: "sessions-all" }
                    }
                  ]
                }
              }
            ]
          },
          {
            name: "contentStats",
            path: "contentStats",
            component: () => import("pages/Dashboard/ContentStats/ContentStats.vue"),
            children: [
              {
                name: "content-all",
                path: "",
                component: () => import("pages/Dashboard/ContentStats/All.vue"),
                meta: {
                  title: "Content Stats",
                  icon: "fas fa-mobile-alt",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Content Stats"
                    }
                  ]
                }
              },
              {
                name: "content-agent",
                path: ":id",
                component: () => import("pages/Dashboard/ContentStats/All.vue"),
                meta: {
                  title: "Content Stats",
                  icon: "fas fa-mobile-alt",
                  tabs: [],
                  breadcrumb: [
                    {
                      icon: "home",
                      label: "Home",
                      link: { name: "home" }
                    },
                    {
                      label: "Content Stats",
                      link: { name: "content-all" }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("pages/Auth/Logout.vue")
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    component: () => import("pages/Error404.vue")
  }
];

export default routes;
