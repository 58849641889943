import Vue from "vue";
import VueRouter from "vue-router";
import { QSpinnerFacebook } from "quasar";
import { SessionStorage } from "quasar";
import routes from "./routes";

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({ store, Vue }) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });

  Router.beforeEach(async (to, from, next) => {
    const path = to.path;
    const query = to.query;

    if (to.meta.breadcrumb) {
      for (const breadcrumb of to.meta.breadcrumb) {
        if (typeof breadcrumb.metaLabel === 'function') {
          breadcrumb.label = breadcrumb.metaLabel(to)
        }
      }
    }

    if (path === "/" && query.c) {
      let loggedIn = null;
      try {
        const decodedString = atob(query.c);
        const jsonResult = JSON.parse(decodedString);
        if (!jsonResult.token) throw new Error("Token not found");
        await store.dispatch("auth/config", { token: jsonResult.token });
        loggedIn = await tokenLogin(Vue.prototype, jsonResult.token, store.state.auth.config.userpool);
      } catch (e) {}
      if (loggedIn) {
        console.log('Token login successful');
        next("/portal");
      } else {
        console.error('Token login unsuccesfull');
        next({ path: "/logout" });
      }
    } else if (path.indexOf("/portal/") != -1) {
      if (!isAuthorized(Vue.prototype)) {
        next({ path: "/logout" });
      }
      next();
    } else {
      next();
    }
  });

  patchRouterMethod(Router, "push");
  patchRouterMethod(Router, "replace");

  return Router;
}

async function tokenLogin(_this, token, userPool) {

  // show spinner
  _this.$q.loading.show({
    spinner: QSpinnerFacebook,
    spinnerColor: "white",
    spinnerSize: 140,
    backgroundColor: "primary",
    message: "Some important process is in progress. Hang on...",
    messageColor: "white"
  });

  const CognitoExpress = require('cognito-express');

  // Docs: https://www.npmjs.com/package/cognito-express
  const cognitoExpress = new CognitoExpress({
    region: userPool.region,
    cognitoUserPoolId: userPool.UserPoolId,
    tokenUse: "id", //Possible Values: access | id
    tokenExpiration: 3600000
  });

  //  Login action
  try {
    const result = await cognitoExpress.validate(token);
    // Save auth data
    SessionStorage.set("user", {
      first_name: result.given_name,
      last_name: result.family_name,
      username: result.nickname,
    });
    SessionStorage.set("token", token);
    // Hide spinner
    _this.$q.loading.hide();
    return true;
  } catch (e) {
    _this.$q.loading.hide();
    console.error('Invalid token', e);
    return false;
  }  
}

function isAuthorized(_this) {
  let value = SessionStorage.has("token");
  return value;
}

function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch(error => {
      if (error === undefined || error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}
