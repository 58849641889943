import Vue from "vue";
import axios from "axios";
import { SessionStorage } from 'quasar'

const getAuthHeaders = () => {
  const headers = {};
  const accessToken = SessionStorage.getItem("token");
  if (accessToken) {
    headers['access_token'] = accessToken;
  }

  return headers;
}

// request interceptor
axios.interceptors.request.use(
  config => {
    const authHeaders = getAuthHeaders();
    config.url = config.url;
    config.headers = { ...config.headers, ...authHeaders };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  response => {
    return response.data.body ? response.data.body : response.data;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          console.log("Something happend: ", error.response.status);
          break;
        case 500:
          console.log("Something happend: ", error.response.status);
          break;
        case 401:
          if (error.response.status === 401 && !window.refreshing) {
            //window.refreshing = true;
            SessionStorage.clear();
           // window.location = "/logout";
          }
          break;
        default:
          console.log("Something happend: ", error.response.status);
          break;
      }
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;



